
import {
  Vue,
  Component,
  Prop,
  PropSync,
  Watch,
} from 'vue-property-decorator';
import { Property, Value } from '@/types/vjsf';
import { Time } from '@/helpers/converters/time';

// Lazy loading imports
const TimePicker = () => import(
  /* webpackChunkName: "form-time-picker" */
  /* webpackPrefetch: false */
  '@/components/formElements/elements/TimePicker.vue'
);
const Tooltip = () => import(
  /* webpackChunkName: "form-tooltip" */
  /* webpackPrefetch: false */
  '@/components/Tooltip/Tooltip.vue'
);

@Component({
  name: 'TimeField',
  components: {
    TimePicker,
    Tooltip,
  },
})
export default class TimeField extends Vue {
  @PropSync('value', { required: true })
  private time!: Value; // HH:mmZ

  @Prop({ required: true })
  private readonly property!: Property;

  @Prop({ required: true })
  private readonly id!: string;

  @Prop({ default: true })
  private readonly showIcon!: boolean;

  @Prop({ required: true })
  private readonly required!: boolean;

  private filteredValue = '';

  @Watch('time', { deep: true, immediate: true })
  private correctTime(): void {
    if (this.time === null) {
      this.filteredValue = '';
    } else {
      [this.filteredValue] = `${this.time}`.split('Z');
    }
  }

  public async submit(): Promise<void> {
    await this.formatInput();
    this.$emit('submit');
  }

  protected reset(): void {
    this.filteredValue = '';
    this.time = null;
  }

  protected setToday(): void {
    const date = new Date();
    const time = new Time(date.getHours(), date.getMinutes());
    this.filteredValue = time.getTimeString();
    this.time = `${time.getTimeString()}Z`;
  }

  private formatInput(): Promise<void> {
    return new Promise((resolve) => {
      if (this.filteredValue === '') {
        return;
      }

      const previousTime = this.time as string;
      const time = new Time();

      if (this.filteredValue.includes(':')) {
        // Time input as HH:mm
        const splitValue = this.filteredValue.split(':');
        time.setHours(splitValue[0]);
        time.setMinutes(splitValue[1]);
      } else if (/,|./.test(this.filteredValue)) {
        // Time input as a decimal number
        const splitValue = this.filteredValue.split(/\.|,/);
        time.setHours(splitValue[0]);
        time.setMinutesFromDecimal(splitValue[1]);
      } else {
        // Time input as integer
        time.setHours(this.filteredValue);
      }

      this.time = `${time.getTimeString()}Z`;
      this.filteredValue = time.getTimeString();

      if (previousTime !== `${time.getTimeString()}Z`) {
        this.$emit('change');
      }

      resolve();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private filter(event: KeyboardEvent, previousValue: string): void {
    const input = event.key;
    const regex = /^[0-9]*([,.:][0-9]*)?$/gm;

    if (!regex.test(`${previousValue}${input}`)) {
      event.preventDefault();
    }
  }
}

